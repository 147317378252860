function loadProduct_Legacy(type_filter, id, type_page) {
    var tmp_qte = $("#qteProd_" + id).val();

    if (typeof id == "undefined") {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof type_page == "undefined") {
        type_page = "product";
    }

    var $form = $("#form_fiche_produit");
    var $input = $("#" + type_filter + "Prod" + id_prod, $form);
    var type = type_filter;
    var $hidden = $("#" + type_filter + "Prod" + id_prod, $form);
    var $produit_id_new = $("#" + type_filter + "Prod" + id_prod).attr(
        "data-produitid"
    );

    var data = $form.serialize();
    data += "&produit_id_new=" + $produit_id_new;
    data += "&type=" + type_filter;
    data += "&type_page=" + type_page;

    if (typeof id != "undefined") {
        data += "&couleurProd=" + $("#couleurProd" + id_prod).val();
        data += "&tailleProd=" + $("#tailleProd" + id_prod).val();
        data += "&produit_principal=" + $("#produit_principal" + id_prod).val();
        var produit_principal = $("#produit_principal" + id_prod).val();
    }

    if (isGlobalEActive) {
        data.warehouse_only = 1;
    }

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_get_product_regroup_ref.php",
        data: data,
        beforeSend: function() {
            $("#bloc_btn_loader").show();
            $("#btn_error").fadeOut(300);
        },
        success: function(response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            if (response.success == "ok") {
                var datas = response.datas;

                if (type_page == "product") {
                    var $html_main = $(datas.html_main);
                    var $html_middle = $(datas.html_middle);
                    var $fiche = $("#main_wrapper");
                    var $form = $("#wrapper_right_fiche");
                    var replace_main = new Array(
                        "#product_name",
                        "#list_caract",
                        "#modbox .infos",
                        ".product_detail_tab_holder",
                        ".content_img_visu_wrapper",
                        ".wrapper_prix_product",
                        "#description_produit",
                        "#reference_fiche_produit",
                        "#visu_popup_ajout_panier",
                        ".restock_date",
                        "#script_zoom"
                    );
                    var replace_middle = new Array(
                        "#ligne_couleur",
                        "#ligne_taille",
                        "#ligne_qte",
                        "#bloc_btn_active",
                        "#bloc_add_alert",
                        "#addToWishlistButtonContainer",
                        "#bloc_add_basket"
                    );

                    replace_main.push("#social_network");
                    replace_main.push(".fond_middle");

                    for (var i = 0; i < replace_main.length; i++) {
                        var id = replace_main[i];
                        $(id, $fiche).html($(id, $html_main).html());
                    }

                    for (var i = 0; i < replace_middle.length; i++) {
                        var id = replace_middle[i];
                        $(id, $form).html($(id, $html_middle).html());
                    }

                    $("#sendfriend_form_buttons", "#sendfriendbox").html(
                        $(
                            "#sendfriendbox #sendfriend_form_buttons",
                            $html_main
                        ).html()
                    );

                    $("#produit_id", "#form_fiche_produit").val(
                        $("#produit_id", $html_middle).val()
                    );
                    $("#produit_principal", "#form_fiche_produit").val(
                        $("#produit_principal", $html_middle).val()
                    );
                    $("#produit_lot_nb", "#form_fiche_produit").val(
                        $("#produit_lot_nb", $html_middle).val()
                    );

                    $("#hdn_product_id", "#form_fiche_produit").val(
                        $("#hdn_product_id", $html_middle).val()
                    );
                    $("#hdn_is_model", "#form_fiche_produit").val(
                        $("#hdn_is_model", $html_middle).val()
                    );

                    var heads = $("[data-tab-head]");
                    var containers = $("[data-tab-container]");

                    var activateTab = function(event) {
                        event.preventDefault();

                        var element = $(this);
                        var tab_number = element.attr("data-tab");
                        var head_container = element.parent();
                        var container_name, container, scrollpane;

                        head_container
                            .addClass("current")
                            .siblings()
                            .removeClass("current");
                        container_name = head_container
                            .parent()
                            .attr("data-tab-head");
                        container = containers.filter(
                            "[data-tab-container='" + container_name + "']"
                        );
                        scrollpane = container
                            .find("[data-tab]")
                            .hide()
                            .filter("[data-tab='" + tab_number + "']")
                            .show()
                            .find(".scroll-pane");

                        if (scrollpane.size()) {
                            scrollpane.jScrollPane();
                        }
                    };

                    heads.each(function() {
                        var head = $(this);
                        var children = head.children();
                        children
                            .removeClass("current")
                            .filter(":first")
                            .addClass("current");
                        children.find("[data-tab]").click(activateTab);
                    });

                    $(".img_gamme").each(function() {
                        $(this).recalImg();
                    });

                    $(".bxslider-4").each(function() {
                        var slider = $(this);
                        if (slider.children().size() > 4) {
                            slider.bxSlider({
                                pager: false,
                                minSlides: 2,
                                maxSlides: 4,
                                slideWidth: 280,
                                slideMargin: 10,
                                infiniteLoop: true,
                                hideControlOnEnd: true,
                                auto: true,
                            });
                        }
                    });

                    var string_params = "";
                    if ($("#produit_lot_nb").val() != undefined)
                        string_params +=
                            "&produit_lot_nb=" + $("#produit_lot_nb").val();

                    // RECHARGEMENT QTE
                    var qte = ajax_file(
                        path_relative_root + create_link('ajax_liste_quantity') + "?idprod=" + datas.product.id + "&idcouleur=" + datas.product.color.id + "&idtaille=" + datas.product.size.id + string_params
                    );
                    $("#liste_qte").html(qte);

                    if ($(".restock_date").length > 0) {
                        $(".eclat_product_nouveaute_fiche_produit").show();
                        $("#bloc_add_alert").show();
                        $("#bloc_add_basket").hide();
                        $("#ligne_qte").append($(".restock_date"));
                    } else {
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $("#bloc_add_alert").hide();
                        $("#bloc_add_basket").show();
                    }

                    // WISHLIST
                    checkProductInWishlist();
                    $("#addToWishlistButton").on("click", addToWishlist);
                } else if (type_page == "look") {
                    var $html_main = $(datas.main_content);

                    $("#ref_look_" + produit_principal).html(
                        $("#ref_look_" + produit_principal, $html_main).html()
                    );
                    $("#look_add_lightbox_" + produit_principal).html(
                        $(
                            "#look_add_lightbox_" + produit_principal,
                            $html_main
                        ).html()
                    );

                    calculTotal("slide_look_detail");
                    $("#itm_thumb_list").empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList(thumbnails);

                    var $id_current = id_prod.substring(
                        id_prod.lastIndexOf("_") + 1
                    );
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme(
                        "liste_qte_" + datas.product.id,
                        datas.product.id,
                        path_relative_root
                    );
                }

                Custom.init();
            } else {
                $("#btn_error").fadeIn();
            }
        },
        complete: function() {
            $("#bloc_btn_loader").fadeOut(300);
        },
    });
}

var look_add = false;

function addLook(loader) {
    $(loader).fadeIn(200);

    look_add = true;

    calculTotal("slide_look_detail", true);
}

function calculTotal(id, process_look) {
    var $form = $(document.getElementById(id));
    var data = $form.serialize();

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax_calcul_total.php",
        data: data,
        success: function(response) {
            try {
                response = JSON.parse(response);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (response.success == "ok") {
                var datas = response.datas;
                var total = datas.total;

                $("#look_total", "#look_info").val(total);
                $(".price_total", "#look_info").html(datas.total_format);

                if (process_look) {
                    if (total > 0) {
                        if (!addToCartTmpl) {
                            addToCartTmpl = document
                                .getElementById("modbox")
                                .innerHTML.trim();

                            Mustache.parse(addToCartTmpl);
                        }

                        buildLookAddedModbox(false);
                        processlook();
                    } else {
                        var $alertbox = $("#alertbox1");

                        res = ajax_file(
                            path_relative_root +
                                "ajax_sprintf.php?arg1=js_error_quantity"
                        );

                        html_err =
                            '<div class="txt_alert"></div><div class="rosette"></div>';
                        html_err +=
                            '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
                        if ($alertbox.length == 0) {
                            $alertbox = $(
                                '<div id="alertbox1">' + html_err + "</div>"
                            );
                            $("body").append($alertbox.hide());
                        }

                        $alertbox.find(".txt_alert").html(res);
                        openMultiShad("alertbox1");

                        $("#bloc_btn_look").show();
                        $("#bloc_btn_loader_look").hide();

                        if ($(".loader", "#bloc_add_basket").length > 0) {
                            $(".loader", "#bloc_add_basket").hide();
                        }
                    }
                }
            }
        },
    });
}

function loadPrevisu() {
    var cpt = 0,
        $array_product_previsu = [];

    $(".array_produit_total")
        .map(function() {
            var $current_product = $(this).val();
            var $current_product_qte = $("#qteProd_" + $current_product).val();

            if ($current_product_qte > 0) {
                $array_product_previsu[cpt] = [];

                $array_product_previsu[cpt]["id"] = $current_product;
                $array_product_previsu[cpt]["img"] = $(
                    "#img_produit_" + $current_product
                ).attr("src");
                $array_product_previsu[cpt]["price"] = $(
                    "#price_" + $current_product
                ).html();
                $array_product_previsu[cpt]["qte"] = $current_product_qte;

                cpt++;
            }
        })
        .get();

    return $array_product_previsu;
}

function processlook() {
    $(".submit_panier").each(function() {
        var $elem = $(this).attr("id");
        var $id = $elem.substring($elem.lastIndexOf("_") + 1);
        var $val_item = $("#qteProd_" + $id).val();

        if ($val_item > 0) {
            $("#look_add_lightbox_" + $id).show();
            $(this).click();
        } else {
            $("#look_add_lightbox_" + $id).hide();
        }
    });

    setTimeout(showsendfriendmodal, 1000, 0, path_relative_root, "look_basket");
}

function removeThumb(id) {
    var select = document.getElementById("qteProd_" + id);

    this.className += " closing";

    window.setTimeout(function() {
        select.value = parseFloat(select.value) - 1;

        $(select).change();
    }, 160);
}

function createThumb(data) {
    var template = document.getElementById("thumb_tpe").innerHTML,
        rendered = Mustache.render(template, data),
        new_item = document.createElement("li");

    new_item.className = "itm_thumb";
    new_item.innerHTML = rendered;

    document.getElementById("itm_thumb_list").appendChild(new_item);
}

function parseThumbList(list) {
    var thumb, qte, i;

    for (thumb in list) {
        if (list.hasOwnProperty(thumb)) {
            thumb = list[thumb];
            qte = thumb.qte;

            delete thumb.qte;

            for (i = 0; i < qte; i += 1) {
                createThumb(thumb);
            }
        }
    }
}
